import Overview from "../../components/Content/Project/Overview";
import {useEffect, useState} from "react";
import {resetCurrentCatalog, resetCurrentQuestionCategory, setSelectedMenuPoint} from "../../utils/breadcrumb";
import {loggedInUser, setInQuestionCatalog} from "../../utils/helpers";
import {useLocation} from "react-router-dom";
import {fetchErtProjects} from "../../utils/api/projects";
import {useAuth} from "../../hooks/useAuth";
import Preloader from "../../components/General/Preloader";
import useDsLoaderSolution from "../../hooks/useLoaderDsSolution";

export default function ShowList() {
    useDsLoaderSolution("https://projekt-manager.e-recht24.dev/js/loader-ds-solution.js");

    const location = useLocation();
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { makeAuthenticatedRequest, accessToken} = useAuth();

    useEffect(() => {
        setSelectedMenuPoint("Mein Projekt", location.pathname);
        resetCurrentQuestionCategory();
        resetCurrentCatalog();
        setInQuestionCatalog(false);
        if(accessToken) {
            if(projects.length === 0) {
                fetchErtProjects(accessToken, makeAuthenticatedRequest, setProjects, setIsLoading);
            }
        }
    },[makeAuthenticatedRequest])

    return <>
        <h1 className="text-2xl font-bold text-ere-content-blue outlet-title self-start mt-[16px] mb-[16px]">Mein Unternehmen</h1>

        <div className="m-0 flex flex-col risk-card md:w-full h-fit lg:bg-white  md:p-0 md:flex-row xl:justify-between w-full">
            <div className="w-[100%] lg:flex-row">
                <div className="flex flex-col md:flex-none md:flow-root md:items-center">
                    <h2 className="float-left text-xl text-ere-content-blue mb-[12px] font-bold">Projekt hinzufügen</h2>
                </div>
                <p className="xl:text-[16px] mb-[40px] text-ere-grey-alternative md:self-start self-center leading-[normal]">
                    Fügen Sie Ihr Projekt aus dem eRecht24 Premium Projekt Manager hinzu, um schnell und einfach Rechtstexte wie Impressum, Datenschutzerklärung und Cookie-Einwilligung zu erzeugen und somit wichtige Aufgaben erledigen zu können.
                </p>
                {process.env.REACT_APP_PROJECT_MANAGER_ENV === 'staging' && loggedInUser && loggedInUser.erecht24_project_uuid ?
                    <>
                        <p className="xl:text-[16px] mb-[16px] text-ere-grey-alternative md:self-start self-center leading-[normal] font-[700]">Verknüpftes Projekt</p>
                        <div id="pm20-container" className="mb-[24px]"/>
                    </>
                :
                    <></>
                }
                {projects.length === 0 ?
                    <></>
                :
                    <p className="xl:text-[16px] mb-[40px] text-ere-grey-alternative md:self-start self-center leading-[normal]">
                        Bitte wählen Sie Ihr Projekt aus, welches Sie hinzufügen möchten.
                    </p>
                }
            </div>
        </div>
        {isLoading ?
            <Preloader/>
        :
            <>
                {projects.length === 0 ?
                    <>
                        <p className="text-ere-grey-alternative mb-[24px]">Es sind keine Projekte hinterlegt</p>
                        <a id="erecht24-create-project" href={process.env.REACT_APP_PROJECT_MANAGER_URL} target="_blank" className="hover:btn-secondary flex items-center justify-center h-[45px] w-[200px] place-self-center btn btn-secondary-active-and-hover py-[12px] text-[14px]">
                            PROJEKT ANLEGEN
                        </a>
                    </>
                :
                    <Overview ertProjects={projects}/>
                }
            </>
        }

    </>
}