import {useEffect, useState} from "react";
import {fetchCategories} from "../utils/api/categories";
import Header from "./Header";
import "../assets/css/sidebar.css";
import {NavLink} from 'react-router-dom';
import {ReactComponent as ArrowsSvg} from "../assets/icons/arrows.svg";
import {ReactComponent as  ArrowDownGray} from "../assets/icons/chevron-top-gray.svg";
import {ReactComponent as  ArrowTop} from "../assets/icons/chevron-top.svg";
import {ReactComponent as AttentionIcon} from "../assets/icons/attention-icon.svg";
import {ReactComponent as DashboardIcon} from "../assets/icons/sidebar/dashboard.svg";
import {ReactComponent as DataProtectionIcon} from "../assets/icons/sidebar/datenschutz.svg";
import {ReactComponent as TasksIcon} from "../assets/icons/sidebar/aufgaben.svg";
import {ReactComponent as DokumentsIcon} from "../assets/icons/sidebar/dokumente.svg";
import {ReactComponent as FirmaIcon} from "../assets/icons/sidebar/firma.svg";
import {ReactComponent as ClientsIcon} from "../assets/icons/sidebar/mandanten.svg";
import {useAuth} from "../hooks/useAuth";
import SidebarLink from "../components/Content/Category/SidebarLink";
import {fetchTasks} from "../utils/api/tasks";
import {loggedInUser} from "../utils/helpers";
import {fetchSkippedQuestions} from "../utils/api/answer";

export default function SidebarComponent({collapsedSidebar, setCollapsedSidebar}) {
    const [arrowsVisible, setArrowsVisible] = useState(true);
    const [categories, setCategories] = useState([]);
    const [skippedQuestions, setSkippedQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {makeAuthenticatedRequest,accessToken} = useAuth();
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [categoryOpenStatus, setCategoryOpenStatus] = useState(false);
    const [tasks, setTasks] = useState([]);

    useEffect(()=> {
        if(accessToken && categories.length === 0 ) {
            fetchCategories(accessToken, makeAuthenticatedRequest, setCategories, setIsLoading);
        }
        fetchSkippedQuestions(accessToken, makeAuthenticatedRequest, setIsLoading, setSkippedQuestions);
    },[makeAuthenticatedRequest])

    useEffect(()=> {
        if( accessToken ) {
            fetchTasks(accessToken, makeAuthenticatedRequest, setTasks, setIsLoading);
        }
    },[makeAuthenticatedRequest]);

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const listCategories = Array.from(Object.values(categories)).map(category => {
        return <SidebarLink key={category.id} category={category} collapsedSidebar={collapsedSidebar} setCollapsedSidebar={setCollapsedSidebar} arrowsVisible={arrowsVisible} setArrowsVisible={setArrowsVisible}/>
    });

    const listUndoneTasks = tasks?.filter(function(task) {
        if(task.state === "open") {
            return task;
        }
    })

    let showUndoneTasks= 0;
    if(listUndoneTasks.length !== 0){
        showUndoneTasks = listUndoneTasks.length;
    }

    function getSkippedQuestions(){
        if(Array.from(Object.values(skippedQuestions)).length !== 0){
            let questions = 0;
            Array.from(Object.values(skippedQuestions)).forEach(questionEntity => {
                questionEntity.skipped_question.forEach((question) => {
                    questions++
                });
            })
            return questions;
        } else {
            return 0;
        }

    }

    return (
        <div className="sidebar-wrapper">
            <div className={`sidebar relative min-h-[724px] h-full shadow-sm bg-white hidden lg:block overflow-visible}`}>
                {arrowsVisible ?
                    <span className="w-6 h-6 rounded relative top-[22px] right-[-281px] cursor-pointer rotate mr-[-10px] delay-500 z-20 inline-grid"
                          onClick={() => {
                              setCollapsedSidebar(!collapsedSidebar);
                              setArrowsVisible(!arrowsVisible);
                              setCategoryOpen(false);
                              setCategoryOpenStatus(false);
                          }}>
                    <ArrowsSvg className="w-[34px] h-[34px] mr-[-2px] z-20"/>
                </span>
                    :
                    <div className="w-[30px] h-[30px]"/>
                }



                <div className={`sidebar overflow-visible h-full top-[-30px] rounded-sm overflow-y-auto duration-300 transition-all ease-linear text-[14px]  ${collapsedSidebar ? "w-[72px] h-[694px] overflow-y-hidden" : "w-[295px] overflow-x-hidden"}`}>
                    <Header className="header1 font-[600] " collapsed={collapsedSidebar}
                            collapsedFunction={() => setCollapsedSidebar(!collapsedSidebar)} arrowsVisible={arrowsVisible}
                            arrowsVisibleFunction={() => setArrowsVisible(!arrowsVisible)}></Header>
                    <div className={`menu text-ere-grey h-auto text-base flex flex-col  ${collapsedSidebar ? "mt-[56px]" : "mt-[46px] animate-menu  duration-500 "}`}>
                        <NavLink to="/" id="dashboard"
                                 className = {({isActive})=>{
                                     return (
                                         (isActive ? "active dashboard" : "gray dashboard")
                                     );
                                 }}
                                 onClick={() => {
                                     if (!collapsedSidebar && categoryOpen)
                                         setCategoryOpen(false);
                                     if(!collapsedSidebar && categoryOpenStatus)
                                         setCategoryOpenStatus(false);
                                 }}>

                            <li className={`menu-item level-0 ${collapsedSidebar ? "bigdist" : "smalldist"}`}>
                                <DashboardIcon className="sidebar-icon ml-[2px]"/>
                                <span className={`category-name pl-[15px]  ${collapsedSidebar ? "hidden" : ""}`}>Dashboard</span>
                            </li>
                            {collapsedSidebar ?
                                <div className="invisible-wrapper left-0 group w-[300px]  h-[40px] group">
                                    <div className="group-hover:flex sidebar-tooltip rounded-[4px] duration-300 transition-all ease-linear shadow-sm bg-white py-[5px] px-[16px] flex items-center">
                                        <h3 className="uppercase text-ere-checkbox-blue text-xs"><a href="/">Dashboard</a></h3>
                                    </div>
                                </div>
                                :
                                ""
                            }
                        </NavLink>
                        <div id="organisation" className={`organisation sub-menu-wrapper menu-item  relative block ${(!collapsedSidebar && categoryOpen) ? " " : "pb-0"}`}>
                            <div className={`flex level-0 items-center group basis-class ${collapsedSidebar ? "bigdist" : "smalldist"}`}
                                 onClick={() => {
                                     if (!collapsedSidebar)
                                         setCategoryOpen(!categoryOpen);
                                     if(categoryOpenStatus)
                                         setCategoryOpenStatus(false);
                                 }}>

                                <NavLink to={"/organisation"}
                                         className={({isActive}) => {
                                             return (
                                                 ((isActive) ? "active w-full h-full flex items-center" : "gray w-full h-full flex items-center")
                                             );
                                         }}
                                >
                                    <FirmaIcon className={`sidebar-icon  ${collapsedSidebar ? "group-hover:ml-[-5px]" : ""}`}/>
                                    {collapsedSidebar ?
                                        <div className="arrow-top-wrapper">
                                            <ArrowTop className="rotate-90 arrow-right-icon-hover-basis"></ArrowTop>
                                            <div className="overlay-element inline group-hover:hidden"></div>
                                        </div>
                                        :
                                        ""
                                    }
                                    <span className={`category-name mb-[0px] ${collapsedSidebar ? "hidden" : ""} `}>Mein Unternehmen</span>
                                    {collapsedSidebar ?
                                        <div className="invisible-wrapper">
                                            <div className="sidebar-submenu-right rounded-[4px] duration-300 transition-all ease-linear shadow-sm bg-white pb-[16px] pt-[20px] px-[18px] min-w-[182px]">
                                                <h3 className="uppercase text-ere-checkbox-blue text-xs mb-[10px]">Mein Unternehmen</h3>
                                                <div className="links-wrapper">
                                                    <NavLink to={"/organisation"} className={({isActive})=>{return((isActive ? "active-menu flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 " : "gray flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 "));}}
                                                             onClick={(e) => {
                                                                 e.target.parentElement.classList.add('active');
                                                                 if (!collapsedSidebar) {
                                                                     setCollapsedSidebar(!collapsedSidebar);
                                                                     setArrowsVisible(!arrowsVisible);
                                                                 } else {
                                                                     setCollapsedSidebar(true);
                                                                 }
                                                             }
                                                    }
                                                    >
                                                        <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Stammdaten</span>
                                                    </NavLink>
                                                    <NavLink to={"/projects"} className={({isActive})=>{return((isActive ? "active-menu flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 " : "gray flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 "));}}
                                                             onClick={(e) => {
                                                                 e.target.parentElement.classList.add('active');
                                                                 if (!collapsedSidebar) {
                                                                     setCollapsedSidebar(!collapsedSidebar);
                                                                     setArrowsVisible(!arrowsVisible);
                                                                 } else {
                                                                     setCollapsedSidebar(true);
                                                                 }
                                                             }}
                                                    >
                                                        <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Mein Projekt</span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </NavLink>
                                {collapsedSidebar || listCategories.length === 0 ?
                                    ""
                                    :
                                    <ArrowDownGray className={`ml-[-37px] transition-all duration-300 arrow-down ${categoryOpen ? "rotate-180 " : "gray-arrow  "}`}/>
                                }
                            </div>
                            {collapsedSidebar ? "" : <div className={categoryOpen ? "sub-menu open " : "closed"}>
                                <NavLink to={"/organisation"} className={({isActive})=>{return((isActive ? "active-menu flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 " : "gray flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 "));}}
                                         onClick={(e) => {
                                             e.target.parentElement.classList.add('active');

                                         }}
                                >
                                    <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Stammdaten</span>
                                </NavLink>
                                <NavLink to={"/projects"} className={({isActive})=>{return((isActive ? "active-menu flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 " : "gray flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 "));}}
                                         onClick={(e) => {
                                             e.target.parentElement.classList.add('active');

                                         }}
                                >
                                    <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Mein Projekt</span>
                                </NavLink>
                            </div>}
                        </div>

                        <div id="status" className={`status sub-menu-wrapper menu-item  relative block ${(!collapsedSidebar && categoryOpenStatus) ? " " : "pb-0"}`}>
                            <div className={`flex level-0 items-center group basis-class ${collapsedSidebar ? "bigdist" : "smalldist"}`}
                                 onClick={() => {

                                     if(!collapsedSidebar)
                                         setCategoryOpenStatus(!categoryOpenStatus);
                                     if(categoryOpen)
                                         setCategoryOpen(false);

                                 }}>
                                <NavLink to={"/categories"}
                                         className = {({isActive}) => {
                                             return (
                                                 ((isActive) ? "active w-full h-full flex items-center" : "gray w-full h-full flex items-center")
                                             );
                                         }}
                                >
                                    <DataProtectionIcon className={`sidebar-icon  ${collapsedSidebar ? "group-hover:ml-[-5px]" : ""}`}/>
                                    {collapsedSidebar ?
                                        <div className="arrow-top-wrapper">
                                            <ArrowTop className="rotate-90 arrow-right-icon-hover-basis"></ArrowTop>
                                            <div className="overlay-element inline group-hover:hidden"></div>
                                        </div>
                                        :
                                        ""
                                    }
                                    <span className={`category-name mb-[0px] ${collapsedSidebar ? "hidden" : ""} `}>Mein Datenschutzstatus</span>
                                    {collapsedSidebar ?
                                        <div className="invisible-wrapper">
                                            <div className=" sidebar-submenu-right rounded-[4px] duration-300 transition-all ease-linear shadow-sm bg-white pb-[16px] pt-[20px] px-[18px] min-w-[182px]">
                                                <h3 className="uppercase text-ere-checkbox-blue text-xs mb-[10px]">Mein Datenschutzstatus</h3>
                                                <div className="links-wrapper">
                                                    {listCategories}
                                                    <NavLink id={`erecht24-skipped-questions`}
                                                             to={`/categories/skipped-questions`}
                                                             className={({isActive})=>{
                                                                 return((isActive ? "active-menu flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 " : "gray flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 "));
                                                             }}
                                                             onClick={(e) => {
                                                                 e.target.parentElement.classList.add('active');
                                                                 if (!collapsedSidebar) {
                                                                     setCollapsedSidebar(!collapsedSidebar);
                                                                     setArrowsVisible(!arrowsVisible);
                                                                 } else {
                                                                     setCollapsedSidebar(true);
                                                                 }
                                                             }}
                                                    >
                                                        <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Übersprungene Fragen</span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </NavLink>
                                {collapsedSidebar || listCategories.length === 0 ?
                                    ""
                                    :
                                    <ArrowDownGray className={`ml-[-37px] transition-all duration-300 arrow-down ${categoryOpenStatus ? "rotate-180 " : "gray-arrow  "}`}/>
                                }
                            </div>
                            {collapsedSidebar ?
                                ""
                            :
                                <div className={categoryOpenStatus ? "sub-menu open " : "closed"}>
                                    {listCategories}
                                    <NavLink id={`erecht24-skipped-questions`}
                                             to={`/categories/skipped-questions`}
                                             className={({isActive})=>{
                                                 return((isActive ? "active-menu flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 " : "gray flex items-center pl-[48px] hover:text-ere-link-blue  animate-grow transition-height duration-500 "));
                                             }}
                                             onClick={(e) => {
                                                 e.target.parentElement.classList.add('active');
                                                 if (!collapsedSidebar) {
                                                     setCollapsedSidebar(!collapsedSidebar);
                                                     setArrowsVisible(!arrowsVisible);
                                                 } else {
                                                     setCollapsedSidebar(true);
                                                 }
                                             }}
                                    >
                                        <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Übersprungene Fragen
                                            {getSkippedQuestions() !== 0 ?
                                                <div className="inline-block bg-ere-orange w-[20px] text-white text-center ml-[8px] rounded-[2px] text-[10px] h-[15px] pt-[1px]">{getSkippedQuestions()}</div>
                                            :
                                                ""
                                            }
                                        </span>

                                    </NavLink>
                                </div>
                            }
                        </div>

                        <NavLink to="/tasks" id="tasks"
                                 className = {({isActive})=> {
                                     return (
                                         (isActive ? "active" : "gray")
                                     );
                                 }}
                                 onClick={() => {
                                     if (!collapsedSidebar && categoryOpen)
                                         setCategoryOpen(false);
                                     if(!collapsedSidebar && categoryOpenStatus)
                                         setCategoryOpenStatus(false);
                                 }}
                        >
                            <li className={`tasks menu-item level-0 ${collapsedSidebar ? "bigdist" : "smalldist"}`}>
                                <TasksIcon className="sidebar-icon"/>
                                {showUndoneTasks ? <div className="w-[7px] h-[7px] bg-ere-orange absolute top-[12px] left-[36px] rounded"></div>  : ""}
                                <span className={`category-name pl-[17px] ${collapsedSidebar ? "hidden" : "flex items-center"}`}>Meine Aufgaben
                                    {showUndoneTasks ?
                                        <div className="inline-block bg-ere-orange w-[20px] text-white text-center ml-[8px] rounded-[2px] text-[10px] h-[15px] pt-[1px]">{listUndoneTasks.length}</div>
                                        :
                                        ""
                                    }
                            </span>
                            </li>
                            {collapsedSidebar ?
                                <div className="invisible-wrapper left-0 group w-[300px]  h-[40px] group">
                                    <div className="sidebar-tooltip rounded-[4px] duration-300 transition-all ease-linear shadow-sm bg-white py-[5px] px-[16px] flex items-center">
                                        <h3 className="uppercase text-ere-checkbox-blue text-xs"><a href="/tasks">Meine Aufgaben</a></h3>
                                    </div>
                                </div>
                                :
                                ""
                            }
                        </NavLink>
                        <NavLink to="/contracts" id="documents"
                                 className={({isActive}) => {
                                     return (
                                         (isActive ? "active documents" : "gray documents")
                                     );
                                 }}
                                 onClick={() => {
                                     if (!collapsedSidebar && categoryOpen)
                                         setCategoryOpen(false);
                                     if(!collapsedSidebar && categoryOpenStatus)
                                         setCategoryOpenStatus(false);
                                 }}
                        >
                            <li className={`menu-item level-0 ${collapsedSidebar ? "bigdist" : "smalldist"}`}>
                                <DokumentsIcon className="sidebar-icon"/>
                                <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Meine Dokumente</span>
                            </li>
                            {collapsedSidebar ?
                                <div className="invisible-wrapper left-0 group w-[300px]  h-[40px] group">
                                    <div className="sidebar-tooltip rounded-[4px] duration-300 transition-all ease-linear shadow-sm bg-white py-[5px] px-[16px] flex items-center w-max">
                                        <h3 className="uppercase text-ere-checkbox-blue text-xs"><a href="/contracts">Meine Dokumente</a></h3>
                                    </div>
                                </div>
                                :
                                ""
                            }
                        </NavLink>
                        { (loggedInUser !== undefined) && ((loggedInUser.protection_officer === 1) || (loggedInUser.support_member === 1)) ?
                            <NavLink to="/clients" id="clients"
                                     className={({isActive}) => {
                                         return (
                                             (isActive ? "active" : "gray")
                                         );
                                     }}
                                     onClick={() => {
                                         if (!collapsedSidebar && categoryOpen)
                                             setCategoryOpen(false);
                                         if(!collapsedSidebar && categoryOpenStatus)
                                             setCategoryOpenStatus(false);
                                     }}
                            >
                                <li className={`menu-item level-0 ${collapsedSidebar ? "bigdist" : "smalldist"}`}>
                                    <ClientsIcon className="sidebar-icon"/>
                                    <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Meine Mandanten</span>
                                </li>
                                {collapsedSidebar ?
                                    <div className="invisible-wrapper left-0 group w-[300px]  h-[40px] group">
                                        <div className="sidebar-tooltip rounded-[4px] duration-300 transition-all ease-linear shadow-sm bg-white py-[5px] px-[16px] flex items-center w-max">
                                            <h3 className="uppercase text-ere-checkbox-blue text-xs"><a href="/contracts">Meine Mandanten</a></h3>
                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                            </NavLink>
                            :
                            ""
                        }
                    </div>

                    <button role="link" onClick={() => openInNewTab('https://www.e-recht24.de/mitglieder/dsgvo-notfall/')} className={`group btn btn-cta hover:btn-cta-active-and-hover text-[14px]  h-[45px] p-[3px] absolute  mb-[24px]  bottom-[5px] flex items-center justify-center transition-width  duration-300 ease-linear ${collapsedSidebar ? "w-[42px] ml-[15px]" : "ml-[23px] w-[247px]"} `}>
                        <AttentionIcon/>
                        <span className={`uppercase ml-1 animate-menu transition-all duration-500 ${collapsedSidebar ? "hidden" : " "}`}>dsgvo-notfall melden</span>
                    </button>
                </div>
            </div>
        </div>);
}