import {globalQuestionProgress, setGlobalQuestionProgress, setGlobalRisk} from "../../utils/helpers";
import Indicator from "../Content/Progress/Indicator";
import '../../index.css';
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchGlobalStats} from "../../utils/api/globalStats";
import {useAuth} from "../../hooks/useAuth";
import Preloader from "../General/Preloader";
import {loggedInUser} from "../../utils/helpers";

export default function Report() {
    const {accessToken, makeAuthenticatedRequest} = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [done, setDone] = useState(0);

    useEffect(() => {
        fetchGlobalStats(accessToken, makeAuthenticatedRequest, setGlobalQuestionProgress, setGlobalRisk, setIsLoading)
    }, [])
    useEffect(() => {
        setDone(globalQuestionProgress.progress_value);
    }, [globalQuestionProgress])

    if(isLoading) {
        return (
            <div className={`min-w-[250px]  relative bg-white border border-sky-950 border-opacity-5 flex flex-col overflow-hidden p-[24px] items-center shadow-sm ${(loggedInUser && !loggedInUser.editable_by_data_protection_officer) ? "h-[281px]" : "h-[243px]"} `} >
                <div className="text-xl font-bold leading-normal text-ere-content-blue ">Ihr Fortschritt</div>
                <Preloader/>
                <button className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover text-[14px] uppercase mt-auto font-black tracking-[0.233px]">
                    <Link to={"/contracts"}>Berichte erstellen</Link>
                </button>
            </div>
        )
    }

    return (
        <div className={`min-w-[250px]  relative bg-white border border-sky-950 border-opacity-5 flex flex-col overflow-hidden p-[24px] text-center shadow-sm ${(loggedInUser && !loggedInUser.editable_by_data_protection_officer) ? "h-[281px]" : "h-[241px]"} `}>
            <div className={`text-xl font-bold leading-normal text-ere-content-blue  ${(loggedInUser && !loggedInUser.editable_by_data_protection_officer) ? "mb-[30px]" : "mb-[10px]"} `}>Ihr Fortschritt</div>
            <Indicator done={done} small={false} className="pt-[53px]" />
            <button className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover text-[14px] uppercase mt-auto font-black tracking-[0.233px]">
                <Link to={"/contracts"}>Bericht erstellen</Link>
            </button>
        </div>
    )
}